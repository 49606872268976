import { Card } from "react-bootstrap";
import styles from "./announcement-card.module.scss";
import TGButtonVariants from "../../shared/tg-button-variants";
import React from "react";
import { NewsList } from "../../OSCI/src/models/models";
import { Link } from "react-router-dom";
interface AnnoucementCardProps {
  data?: any;
}
export const AnnouncementCard: React.FC<AnnoucementCardProps> = ({ data }) => {
  const handleCardClick = (link: string) => {
    window.open(link);
  }
  return (
    <Link style={{ textDecoration: 'none' }} to={data?.redirectionLink} target="_blank">
    <Card className={`promoCard ${styles.promoCards}`} >
      <Card.Img src={data?.imageUrl} className={`promoImage ${styles.promoImages}`} alt={data?.imageAlt} />
      <div className={styles.imageOverlay}></div>
      <Card.ImgOverlay className={styles.cardOverlay}>
        <div className={styles.cardBody}>
          <div className={styles.cardCaption}>
            <div className={styles.cardTitleWrapper}>
              <Card.Title className={styles.cardTitle}>
                {data?.heading}
              </Card.Title>
              <Card.Subtitle className={styles.cardSubTitle}>
                {data?.date}
              </Card.Subtitle>
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <button className={styles.readMoreButton} onClick={() => handleCardClick(data?.redirectionLink)} >{data?.buttonText}</button>
          </div>
        </div>
      </Card.ImgOverlay>
    </Card>
    </Link>
  );
};

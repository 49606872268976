import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import styles from "./tg-button-variants.module.scss";
import TGIcon from "../tg-icon";

interface Props {
  border?: string;
  buttonDisabled?: boolean;
  borderDisabled?: string;
  borderHover?: string;
  boxShadow?: string;
  boxShadowDisabled?: string;
  boxSHadowHover?: string;
  customClass?: string;
  bgColor?: string;
  bgColorDisabled?: string;
  bgColorHover?: string;
  textColor?: string;
  textColorDisabled?: string;
  textColorHover?: string;
  borderRadius?: string;
  height?: string;
  width?: string;
  padding?: string;
  display?: string;
  justifyContent?: string;
  alignItems?: string;
  gap?: string;
  fontFamily?: string;
  fontSize?: string;
  fontStyle?: string;
  fontWeight?: string;
  lineHeight?: string;
  label: string | undefined;
  iconName?: string;
  fillColor?: string;
  size?: string;
  align?: string;
  buttonSize?: any;
  children?: any;
  marginTop?: any;
  //onClick?:()=>void;
  onClick?: (event: React.FormEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  spacing?: string;
}

const TGButtonVariants = (props: Props) => {
  return (
    <Button
      className={`${styles["primary"]} ${
        styles[props.customClass ? props.customClass : ""]
      }`}
      style={
        {
          "--button-border-radius": `${props.borderRadius}`,
          "--button-bg-color": `${props.bgColor}`,
          "--button-bg-color-disabled": `${props.bgColorDisabled}`,
          "--button-bg-color-hover": `${props.bgColorHover}`,
          "--button-text-color": `${props.textColor}`,
          "--button-border": `${props.border}`,
          "--button-border-disabled": `${props.borderDisabled}`,
          "--button-border-hover": `${props.borderHover}`,
          "--button-box-shadow": `${props.boxShadow}`,
          "--button-box-shadow-disabled": `${props.boxShadowDisabled}`,
          "--button-box-shadow-hover": `${props.boxSHadowHover}`,
          "--button-display": `${props.display}`,
          "--button-padding": `${props.padding}`,
          "--button-justify-content": `${props.justifyContent}`,
          "--button-align-items": `${props.alignItems}`,
          "--button-gap": `${props.gap}`,
          "--button-font-family": `${props.fontFamily}`,
          "--button-font-size": `${props.fontSize}`,
          "--button-font-style": `${props.fontStyle}`,
          "--button-font-weight": `${props.fontWeight}`,
          "--button-line-height": `${props.lineHeight}`,
          "--button-width": `${props.width}`,
          "--button-height": `${props.height}`,
          "--button-text-color-hover": `${props.textColorHover}`,
          "--button-margin-top": `${props.marginTop}`,
        } as any
      }
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.spacing ? props.spacing : null}
      <span className={styles[`button-inner-content-${props.align}`]}>
        {props.iconName && (
          <TGIcon
            icon={props.iconName}
            fillColor={props.fillColor || ""}
            size={props.size || ""}
          />
        )}
        {props.label}
      </span>
      {props.children}
    </Button>
  );
};

export default TGButtonVariants;

import React, { FC, useEffect, useState } from "react";
import styles from "./tg-banner.module.scss";
import TGIcon from "../tg-icon";
import TGPlaceholder from "../tg-placeholder";
import { t } from "i18next";
interface NestedItem {
  bannerHeading: string;
  description: string;
}

interface NotificationProps {
  className: string;
  contentContainer: string;
  textContainer: string;
  secondaryContainerContent: string;
  iconClass: string;
  data: any;
  actionText: string;
  secondaryContainer: string;
  onClose: () => void;
  onIconClick: () => void;
  show: boolean;
  contentClass: string;
  isLoading: boolean;
}

const TGBanner: FC<NotificationProps> = ({
  className,
  iconClass,
  contentClass,
  data,
  actionText,
  contentContainer,
  textContainer,
  secondaryContainer,
  secondaryContainerContent,
  onClose,
  onIconClick,
  show,
  isLoading,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % data.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [data.length]);
  return (
    <div
      className={`${styles[className]} global-content-padding ${
        show ? "" : "d-none"
      }`}
      onClick={onIconClick}
    >
      <div className={styles[contentContainer]}>
      <div className={styles[iconClass]} role="button" aria-label="notification icon" tabIndex={0}>
          <TGIcon icon="notification-icon" size={"18"} fillColor={"white"} />
        </div>
        <div className={styles[textContainer]}>
          {isLoading ? (
            <TGPlaceholder
              typeClass="p"
              animationVariant="wave"
              variant="text"
              sizeClass={8}
              numberOfLines={1}
              loaderStyleClass="bannerContainer"
            />
          ) : (
            <div>
              {data.map((item: any, index: any) => (
                <div
                  key={index + 1}
                  className={`announcement ${
                    index === currentIndex ? `${styles["slide-in"]}` : ""
                  }`}
                  dangerouslySetInnerHTML={{
                    __html: item.bannerHeading,
                  }}
                  style={{ display: index === currentIndex ? "block" : "none" }}
                  role="button"
                  tabIndex={0}
                />
              ))}             
            </div>
          )}
        </div>
        <div className={styles[secondaryContainer]}></div>
        <div onClick={onIconClick} role="button" tabIndex={0}>
          <span className={styles[secondaryContainerContent]}>
            {t("notification_banner_see_all_notices")}
          </span>
        </div>
        <div onClick={onClose} role="button" aria-label="close button" tabIndex={0}>
          <TGIcon icon="notification-close" size={"20"} fillColor={"white"} />
        </div>
      </div>
    </div>
  );
};

export default TGBanner;

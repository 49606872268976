import React, { useEffect, useState } from "react";
import TGButton from "../tg-button";
import styles from "./storybook.module.scss";
import { Button, Col, Container, ListGroup, Row } from "react-bootstrap";
import cardData from "../../utils/data/cardData.json";
import TGIcon from "../tg-icon";
import TGTextField from "../tg-text-field";
import TGCard from "../tg-card";
import cardPrimary from "../../utils/data/card.json";
import TGPills from "../tg-pills";
import TGAutoSuggest from "../tg-autosuggest";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { airportDataRequest } from "../../OSCI/src/slice/bookingWidgetSlice";
import TGSearch from "../tg-search";
import TGHead from "../tg-head";
import { TGRadio } from "../tg-radio";
import { extractFirstWord, capitalize } from "../../utils/helper";
import { PassengerProps } from "../../OSCI/src/models/models";
import TGButtonVariants from "../tg-button-variants";
import { TGCardSub } from "../tg-card-sub";
import TGAdCarousel from "../tg-ad-carousel";
import { AdCardData, AdCarouselData } from "../../OSCI/src/models/models";
import {
  adCardDataRequest,
  adCarouselDataRequest,
} from "../../OSCI/src/slice/adCarouselSlice";
import TGInput from "../tg-input";
import TGSelection from "../tg-selection";
import TGIconLabel from "../tg-icon-label";
import PassengerClass from "../../OSCI/src/component/passenger-class";
import TGOTPInput from "../../shared/tg-otp-input";

export const carouselData = [
  {
    imageURl: "/assets/Slide1.jpg",
    mainHeading: "PROMOTION BANNER",
    subHeading:
      "Discover Unrivaled Airline Promotions for Your Next Adventure! Explore a World of Possibilities with Exclusive Deals and Discounts. Fly High and Experience the Ultimate Comfort and Convenience with Our Premium Services. Book Now and Let Your Travel Dreams Take Flight!",
    hasButton: true,
    buttonText: "Book Now",
    buttonLink: "https://www.thaiairways.com/flights/en/",
  },
  {
    imageURl: "/assets/Slide2.jpg",
    mainHeading: "PROMOTION BANNER",
    subHeading:
      "Discover Unrivaled Airline Promotions for Your Next Adventure! Explore a World of Possibilities with Exclusive Deals and Discounts. Fly High and Experience the Ultimate Comfort and Convenience with Our Premium Services. Book Now and Let Your Travel Dreams Take Flight!",
    hasButton: false,
  },
  {
    imageURl: "/assets/Slide1.jpg",
    mainHeading: "PROMOTION BANNER",
    subHeading:
      "Discover Unrivaled Airline Promotions for Your Next Adventure! Explore a World of Possibilities with Exclusive Deals and Discounts. Fly High and Experience the Ultimate Comfort and Convenience with Our Premium Services. Book Now and Let Your Travel Dreams Take Flight!",
    hasButton: true,
    buttonText: "Book Now",
    buttonLink: "https://www.thaiairways.com/flights/en/",
  },
  {
    imageURl: "/assets/Slide2.jpg",
    mainHeading: "PROMOTION BANNER",
    subHeading:
      "Discover Unrivaled Airline Promotions for Your Next Adventure! Explore a World of Possibilities with Exclusive Deals and Discounts. Fly High and Experience the Ultimate Comfort and Convenience with Our Premium Services. Book Now and Let Your Travel Dreams Take Flight!",
    hasButton: false,
  },
  {
    imageURl: "/assets/Slide1.jpg",
    mainHeading: "PROMOTION BANNER",
    subHeading:
      "Discover Unrivaled Airline Promotions for Your Next Adventure! Explore a World of Possibilities with Exclusive Deals and Discounts. Fly High and Experience the Ultimate Comfort and Convenience with Our Premium Services. Book Now and Let Your Travel Dreams Take Flight!",
    hasButton: true,
    buttonText: "Book Now",
    buttonLink: "https://www.thaiairways.com/flights/en/",
  },
  {
    imageURl: "/assets/Slide2.jpg",
    mainHeading: "PROMOTION BANNER",
    subHeading:
      "Discover Unrivaled Airline Promotions for Your Next Adventure! Explore a World of Possibilities with Exclusive Deals and Discounts. Fly High and Experience the Ultimate Comfort and Convenience with Our Premium Services. Book Now and Let Your Travel Dreams Take Flight!",
    hasButton: false,
  },
  {
    imageURl: "/assets/Slide1.jpg",
    mainHeading: "PROMOTION BANNER",
    subHeading:
      "Discover Unrivaled Airline Promotions for Your Next Adventure! Explore a World of Possibilities with Exclusive Deals and Discounts. Fly High and Experience the Ultimate Comfort and Convenience with Our Premium Services. Book Now and Let Your Travel Dreams Take Flight!",
    hasButton: true,
    buttonText: "Book Now",
    buttonLink: "https://www.thaiairways.com/flights/en/",
  },
  {
    imageURl: "/assets/Slide2.jpg",
    mainHeading: "PROMOTION BANNER",
    subHeading:
      "Discover Unrivaled Airline Promotions for Your Next Adventure! Explore a World of Possibilities with Exclusive Deals and Discounts. Fly High and Experience the Ultimate Comfort and Convenience with Our Premium Services. Book Now and Let Your Travel Dreams Take Flight!",
    hasButton: false,
  },
];

interface counts {
  adult: number;
  youth: number;
  child: number;
  infant: number;
}

const initialState = {
  adult: 1,
  youth: 0,
  child: 0,
  infant: 0,
};

const radioData = [
  {
    id: 1,
    label: "Economy & Economy Plus",
  },
  {
    id: 2,
    label: "Business",
  },
  {
    id: 3,
    label: "Business & First",
  },
];
const radioData1 = [
  {
    id: 1,
    label: "Male",
  },
  {
    id: 2,
    label: "Female",
  },
];

const StoryBook = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showContainer, setShowContainer] = useState(false);
  const [counts, setCounts] = useState(initialState);
  const [selection, setSelection] = useState("1 Adult / Economy");
  const [passengerClass, setPassengerClass] = useState("Economy");
  const [selectedOptions, setSelectedOptions] = useState<{
    [setId: number]: number;
  }>({ 1: 1 });

  const dispatch = useDispatch();

  const adCarouselRes = useSelector(
    (state: RootState) => state?.osciReducer?.adCarousel.AdCarouselDetails
  );
  const adCardRes = useSelector(
    (state: RootState) => state?.osciReducer?.adCarousel.AdCardDetails
  );

  const adCarouselData = adCarouselRes.map((x: AdCarouselData) => {
    return {
      ...x,
      image: x.image,
      location: x.location,
      title: x.title,
    };
  });

  const adCardData = adCardRes.map((x: AdCardData) => {
    return {
      ...x,
      title: x.title,
      description: x.description,
      buttonText: x.buttonText,
      buttonUrl: x.buttonUrl,
    };
  });

  useEffect(() => {
    dispatch(adCarouselDataRequest());
  }, [dispatch]);

  useEffect(() => {
    dispatch(adCardDataRequest());
  }, [dispatch]);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  useEffect(() => {
    dispatch(airportDataRequest());
  }, [dispatch]);

  // const renderMultiCityComponents = () => {
  //   return Array.from({ length: count }, (_, index) => (
  //     <>
  //       {index > 1 && (
  //         <Row>
  //           <Col className="d-flex justify-content-end align-items-center">
  //             <TGIconLabel
  //               icon="close-icon"
  //               label="Remove Flight"
  //               position="left"
  //               onClick={handleRemoveFlight}
  //             />
  //           </Col>
  //         </Row>
  //       )}
  //       <PrimaryTabContent
  //         key={index}
  //         onChangeInput={handleChangeInput}
  //         onInputClick={() => {}}
  //       />
  //     </>
  //   ));
  // };

  const handleTabSelect = (selectedTab: number) => {
    console.log(`Selected Tab, ${selectedTab}`);
  };

  const handleMainTabSelect = (selectedTab: number) => {
    console.log(`Selected Tab, ${selectedTab}`);
  };

  const handleClick = () => {
    alert("Clicked!");
  };

  const getTotalcounts = () => {
    return counts.adult + counts.youth + counts.child + counts.infant;
  };

  const handleIncrement = (type: any) => {
    console.log("type", type);
    if (getTotalcounts() < 9) {
      setCounts((prevcounts) => ({
        ...prevcounts,
        [type]: prevcounts[type as keyof counts] + 1,
      }));
    }
  };

  const handleDecrement = (type: any) => {
    console.log("type", type);
    if (type === "adult" && counts.adult > 1) {
      setCounts((prevcounts) => ({
        ...prevcounts,
        [type]: prevcounts[type as keyof counts] - 1,
      }));
    } else if (type !== "adult" && counts[type as keyof counts] > 0) {
      setCounts((prevcounts) => ({
        ...prevcounts,
        [type]: prevcounts[type as keyof counts] - 1,
      }));
    }
  };

  const handleSelection = () => {
    setSelection(`${counts.adult} Adult / ${extractFirstWord(passengerClass)}`);
    // setCounts(initialState); //not resetting to initial values
    setShowContainer(!showContainer);
  };

  // const handleRadioChange = (setId: number, optionId: number) => {
  //   setSelectedOptions({ ...selectedOptions, [setId]: optionId });
  //   console.log('selectedOptions', selectedOptions);
  // };

  const handleClassData = (data: string) => {
    setPassengerClass(data);
  };

  const PassengerComponent = ({ label, description }: PassengerProps) => {
    return (
      <Row className="mb-2" id="adult-row">
        <Col lg={6}>
          <Row>{capitalize(label)}</Row>
          {description && (
            <Row className={styles.customLabel}>{description}</Row>
          )}
        </Col>
        <Col
          lg={6}
          className="d-flex justify-content-end  align-items-center px-0"
        >
          <span className={styles.customGap}>
            {counts[label as keyof counts]}
          </span>
          <Button
            variant="outline-secondary"
            disabled={
              label === "adult"
                ? counts.adult === 1
                : counts[label as keyof counts] === 0
            }
            onClick={() => handleDecrement(label)}
            className={`${styles.customGap} ${styles.incrementDecrementButton}`}
          >
            <img src="./assets/Minus.svg" alt="minus-icon" />
          </Button>
          <Button
            variant="outline-secondary"
            disabled={getTotalcounts() === 9}
            onClick={() => handleIncrement(label)}
            className={styles.incrementDecrementButton}
          >
            <img src="./assets/Add.svg" alt="plus-icon" />
          </Button>
        </Col>
      </Row>
    );
  };

  const dataArr = [
    {
      id: 1,
      listName: "list1",
      src: "",
    },
    {
      id: 2,
      listName: "check2",
      src: "",
    },
    {
      id: 3,
      listName: "val3",
      src: "",
    },
    {
      id: 4,
      listName: "value4",
      src: "",
    },
  ];
  const dataArr1 = undefined;

  return (
    <div style={{ padding: "100px", background: "white" }}>
      <Row>
        <Col className="d-flex gap-2">
          <TGButton label="Primary" variant="primary" buttonSize="lg" />
          <TGButton label="Secondary" variant="secondary" buttonSize="md" />
          <TGButton
            variant="Others"
            label="Check-in"
            customClass="customButton"
          ></TGButton>
        </Col>
      </Row>
      <p>Card Primary</p>
      <TGCard
        variant="Primary"
        cardButton={true}
        data={cardData[0]}
        cardClass="custom-primary"
        optionalCardClass="grey-background"
        bodyClass="custom-body"
        optionalTextClass="custom-text"
        optionalSubtitleClass="custom-subtitle"
        optionalTitleClass="custom-title"
      />
      <TGCard
        variant="Primary"
        cardButton={true}
        data={cardData[1]}
        cardClass="custom-primary"
        optionalCardClass="bluish-background"
        bodyClass="custom-body"
        optionalTextClass="custom-text"
        optionalSubtitleClass="custom-subtitle"
        optionalTitleClass="custom-title"
      />
      <br></br>
      <br></br>
      <br></br>
      <div>
        <TGButtonVariants
          label="Check-in"
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderRadius="30px"
          boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
          bgColor="#684B9B"
          padding="12px 24px"
          textColor="#FFFFFF"
          fontFamily="Inter"
          fontSize="16px"
          fontStyle="normal"
          fontWeight="700"
          lineHeight="160%"
          borderDisabled="0.791px solid #E3D4FE"
          bgColorDisabled="#E3D4FE"
          boxShadowDisabled="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
          bgColorHover="#684b9b"
          borderHover="0.791px solid #E3D4FE"
          boxSHadowHover="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
        />

        <br></br>
        <TGButtonVariants
          label="Modify this trip"
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderRadius="30px"
          boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
          bgColor="#F5EFFF"
          padding="12px 24px"
          textColor="#684B9B"
          fontFamily="Inter"
          fontSize="16px"
          fontStyle="normal"
          fontWeight="700"
          lineHeight="160%"
        />
        <br></br>
        <br></br>
        <TGButtonVariants
          label="Return"
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderRadius="32px"
          bgColor="#344054B2"
          padding="4px 12px"
          textColor="#FEFEFE"
          fontFamily="Inter"
          fontSize="14px"
          fontStyle="normal"
          fontWeight="500"
          gap="8px"
          lineHeight="160%"
        />
        <br></br>
        <br></br>
        <p>Icon placement -left</p>
        <TGButtonVariants
          label="Check-in"
          iconName="tick-icon"
          display="flex"
          alignItems="center"
          borderRadius="15px"
          bgColor="#DFE0EB"
          padding="4px 12px 4px 8px"
          boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
          textColor="#FEFEFE"
          fontFamily="Inter"
          fontSize="12px"
          fontStyle="normal"
          fontWeight="500"
          gap="4px"
          lineHeight="160%"
        />
        <br></br>
        <p>Icon-placement-right</p>
        <TGButtonVariants
          label="Check-in"
          align="right"
          iconName="tick-icon"
          display="flex"
          alignItems="center"
          borderRadius="15px"
          bgColor="#DFE0EB"
          padding="4px 12px 4px 8px"
          boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
          textColor="#FEFEFE"
          fontFamily="Inter"
          fontSize="12px"
          fontStyle="normal"
          fontWeight="500"
          gap="4px"
          lineHeight="160%"
        />
        <br></br>
        <br></br>
        <div style={{ backgroundColor: "#684B9B", padding: "20px" }}>
          <TGButtonVariants
            label="Book Now"
            display="flex"
            justifyContent="center"
            alignItems="center"
            borderRadius="30px"
            bgColor="#FFFFFF"
            padding="8px 24px"
            textColor="#684B9B"
            fontFamily="Inter"
            fontSize="14px"
            fontStyle="normal"
            fontWeight="700"
            gap="8px"
            lineHeight="160%"
          />
        </div>
        <br></br>
        <br></br>
        <p>search flights enabled</p>
        <TGButtonVariants
          label="Search Flights"
          display="flex"
          alignItems="center"
          border="0.791px solid #684B9B"
          borderRadius="30px"
          bgColor="#381B6B"
          padding="8px 32px"
          boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05);"
          textColor="#FFF"
          fontFamily="Inter"
          fontSize="18px"
          fontStyle="normal"
          fontWeight="700"
          gap="8px"
          bgColorDisabled="#E3D4FE"
          textColorDisabled="FFF"
          lineHeight="160%"
        />
        <br></br>
        <p>search flight disabled</p>
        <TGButtonVariants
          label="Search Flights"
          disabled={true}
          display="flex"
          alignItems="center"
          border="0.791px solid #684B9B"
          borderRadius="30px"
          bgColor="#381B6B"
          padding="8px 32px"
          boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05);"
          textColor="#FFF"
          fontFamily="Inter"
          fontSize="18px"
          fontStyle="normal"
          fontWeight="700"
          gap="8px"
          bgColorDisabled="#E3D4FE"
          textColorDisabled="FFF"
          lineHeight="160%"
        />
        <br></br>
        <br></br>
        <TGPills label="ABCD" variant="primary" bg="#F5EFFF" color="#381B6B" />
        <TGPills label="DEF" variant="primary" bg="#381B6B" color="#FFF" />
      </div>

      <br></br>
      <br></br>
      <br></br>
      <p>Card with Teritiary variant</p>
      <TGCard
        variant="Teritiary"
        cardClass="custom-teritiary"
        bodyClass="teritiary-body"
        cardButton={false}
      >
        <>
          <p className="cardTitle">THAI APPLICATION</p>
          <p className="cardText">
            Discover Unrivaled Airline Promotions for Your Next Adventure
            Explore a World of Possibilities with Exclusive Deals and Discounts.
          </p>
        </>
      </TGCard>
      <p>Horizontal Image Card Old</p>
      <p>Horizontal Image Card New based on Secondary Variant</p>
      <TGCard
        variant="Secondary"
        cardButton={false}
        data={cardData[2]}
        cardClass="custom-secondary"
        bodyClass="custom-secondary-body"
        optionalAlignmentClass="optional-secondary-alignment"
        cardImgClass="custom-secondary-image"
      />
      <p>upcoming card sub variant</p>
      <TGCardSub
        iconName="Seat-icon"
        titleText="Seat"
        subText="40A"
      ></TGCardSub>
      <TGCardSub
        iconName="Meal-icon"
        titleText="Meals"
        subText="Not Selected"
      ></TGCardSub>
      <TGCardSub
        iconName="Baggage-icon"
        titleText="Baggage Allowance"
        subText="30 kg (Standard)"
      ></TGCardSub>
      <p>upcoming card</p>
      <TGCard
        variant="Teritiary"
        cardClass="custom-tertiary-upcoming-card"
        bodyClass="teritiary-body"
        cardButton={false}
      >
        <Row className={styles.row1Upcoming}>
          <Col lg={6}>
            <TGButtonVariants
              label="Check-in"
              iconName="tick-icon"
              display="flex"
              alignItems="center"
              borderRadius="15px"
              bgColor="#DFE0EB"
              padding="4px 12px 4px 8px"
              boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
              textColor="#FEFEFE"
              fontFamily="Inter"
              fontSize="12px"
              fontStyle="normal"
              fontWeight="500"
              gap="4px"
              lineHeight="160%"
            />
          </Col>
          <Col></Col>
        </Row>
        <Row>
          <p className={styles.upcomingCardtitle}>
            MISS NONGNAPHA SOMJANTARASOMBAT
          </p>
        </Row>
        <div className={styles.row2Upcoming}>
          <TGCardSub
            iconName="Seat-icon"
            titleText="Seat"
            subText="40A"
          ></TGCardSub>
          <TGCardSub
            iconName="Meal-icon"
            titleText="Meals"
            subText="Not Selected"
          ></TGCardSub>
          <TGCardSub
            iconName="Baggage-icon"
            titleText="Baggage Allowance"
            subText="30 kg (Standard)"
          ></TGCardSub>
        </div>
      </TGCard>
      <p>Vertical Card Old</p>
      <p>Vertical Card New</p>
      <TGCard
        variant="Secondary"
        cardButton={false}
        data={cardData[2]}
        cardClass="custom-secondary-vertical"
        bodyClass="custom-secondary-vertical-body"
        cardImgClass="custom-secondary-vertical-image"
      />

      <p>Modal Basic</p>
      <TGButton
        variant="primary"
        label="Modals"
        buttonSize={"lg"}
        onClick={handleOpenModal}
      />

      <br />
      <br />
      <TGPills label="BKK" color="" bg="" fillColor="" variant="primary" />
      <br />
      <TGPills
        label="BKK"
        color="#684B9B"
        bg="#F5EFFF"
        fillColor=""
        variant="primary"
        iconName="close-icon"
        size="18px"
      />
      <br />
      <br />
      {/* <TGAutoSuggest
        label="Arrival"
        placeholderText="Enter The Arrival"
        type="text"
        onTextChange={() => {}}
        iconName="arrival"
        labelKey="cityName"
        filterArr={["cityName"]}
        // onFocusChange={()=>{}}
      /> */}
      <br />
      <br />
      {/* <TGSearch
        placeholderText="Search For..."
        onTextChange={() => {}}
        iconName="search-icon"
      /> */}
      <br />
      <br />
      <Row className="d-flex gap-2">
        <TGTextField
          label="Name"
          variant={false}
          placeholderText="Enter Your Name"
          type="text"
          onTextChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            console.log("e", e.target.value)
          }
          isLeftIcon
          iconName="departure"
        />
        <TGTextField
          label="Name"
          variant={false}
          placeholderText="Enter Your Name"
          type="text"
          onTextChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            console.log("e", e.target.value)
          }
        />
      </Row>
      <br />
      <br />
      <TGIcon icon="reverse-arrow" fillColor="" size="32px" />
      <TGIcon icon="close-icon" fillColor="" size="24px" />
      <TGIcon icon="help-icon" fillColor="" size="32px" />
      <br />
      <br />
      {/* <TGCarousel variant="primary" carouselData={carouselData} /> */}
      <br />
      <br />
      <TGHead
        label="Special Offer"
        actionText="See All Promotion"
        onClick={() => {}}
      />
      {/* <TGCarousel variant="secondary" carouselData={carouselData} /> */}
      <br />
      <br />
      {/* <Row>
        <Col>
          <TGCarousel variant="tertiary" carouselData={carouselData} />
        </Col>
        <Col>
          <TGCarousel variant="tertiary" carouselData={carouselData} />
        </Col>
        <Col>
          <TGCarousel variant="tertiary" carouselData={carouselData} />
        </Col>
      </Row>
      <br />
      <br />
      <div style={{ background: "#fff" }}>
        <WhereWeFlyMap onHoverCountry="ASA" />
      </div>
      <br />
      <TGNavbar label="Back" handleClick={handleClick} />
      <br />
      <br />
      <br />
      <br />
      {/* Container for displaying custom dropdown
      By default there is container inside the TG custom dropdown
      according to need custom row is passed as children */}
      {/* <Container>
        <Row>
          <Col>
            <TGCustomDropDown
              handleSelection={handleSelection}
              selection={selection}
              showContainer={showContainer}
            >
              <Row className={styles.contentRow}>
                <Col className="px-0">
                  <p className={styles.rowHeading}>Class</p>
                  <TGRadio
                    setId={1}
                    options={radioData}
                    selected={2}
                    onChange={(opt: any) => console.log("test storybook", opt)}
                    name="class"
                    isBorder={true}
                    customClassName="radioContainerStacked"
                    radioClassName="radioInput"
                  />
                </Col>
                <Col className="px-0">
                  <p className={styles.rowHeading}>Passenger</p>
                  <ListGroup id="listgroup-id">
                    <ListGroup.Item className={styles.customListGroupItem}>
                      <PassengerComponent label="adult" />
                    </ListGroup.Item>
                    <ListGroup.Item className={styles.customListGroupItem}>
                      <PassengerComponent
                        label="youth"
                        description="12-15 years"
                      />
                    </ListGroup.Item>
                    <ListGroup.Item className={styles.customListGroupItem}>
                      <PassengerComponent
                        label="child"
                        description="2-11 years"
                      />
                    </ListGroup.Item>
                    <ListGroup.Item className={styles.customListGroupItem}>
                      <PassengerComponent
                        label="infant"
                        description="Under 2 years"
                      />
                    </ListGroup.Item>
                  </ListGroup>
                </Col>
              </Row>
            </TGCustomDropDown>
          </Col>
        </Row>
      </Container> */}
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className={styles.adCarouselContainer}>
        <TGAdCarousel adData={adCardData} />
        <TGAdCarousel adData={adCarouselData} />
      </div>
      <br />
      <br />
      <TGInput
        label="Email"
        onChange={() => {}}
        name="email"
        validation="language"
      />
      <br />
      <br />
      <TGInput
        label="Email"
        onChange={() => {}}
        isError={true}
        errorLabel="Please enter valid data"
        name="email"
        validation="language"
      />
      <br />
      <br />
      <TGSelection
        label="Email"
        onChange={() => {}}
        listData={dataArr}
        headerLabel="Nationality"
        searchPlaceHolder="Enter Your Country Name"
        isLoading={false}
      />
      <br />
      <br />
      <TGSelection
        label="Email"
        onChange={() => {}}
        listData={dataArr}
        headerLabel="Nationality"
        searchPlaceHolder="Enter Your Country Name"
        isError={true}
        errorLabel="Please enter correct data"
        isLoading={false}
      />
      <br />
      <br />
      <TGSelection
        label="Email blank dropdown data"
        onChange={() => {}}
        listData={dataArr1}
        headerLabel="Nationality"
        searchPlaceHolder="Enter Your Country Name"
        isError={true}
        errorLabel="Please enter correct data"
        isLoading={false}
      />
      <br />
      <br />
      <TGOTPInput
        label="Email"
        onChange={() => {}}
        isError={true}
        errorLabel="Please enter valid data"
      />
    </div>
  );
};

export default StoryBook;

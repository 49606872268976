import React, { useEffect, useState } from "react";
import { Breadcrumb, BreadcrumbItem } from "react-bootstrap";
import { Link, useLocation, useParams } from "react-router-dom";
import { parseRouteString, removeLangFromRoute } from "../../utils/helper";
import styles from "./tg-breadcrumb.module.scss";
import TGIcon from "../tg-icon";
import i18n from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { resetAddNomineeDetails } from "../../OSCI/src/slice/fetchNomineeSlice";
import useScreenSize from "../../utils/hook/useScreenSize";
import { RootState } from "store";

interface Props {
  customClass?: string;
}

const TGBreadCrumb = (props: Props) => {
  const location = useLocation();
  const { destination } = useParams();
  const dispatch = useDispatch();
  let isDesktopView: boolean = useScreenSize().deviceSize.width > 767;
  const [currentRoute, setCurrentRoute] = useState("");
  const [unCapitalize, setUnCapitalize] = useState(false);

  const finalDestination = useSelector(
    (state: RootState) => state?.osciReducer?.myTrips?.destination
  );

  useEffect(() => {
    if (location.pathname === `/${i18n.language}/rop/resetpassword`) {
      setCurrentRoute("Reset Password");
    } else if (
      location.pathname === `/${i18n.language}/e-Receipt` ||
      location.pathname === `/${i18n.language}/e-Receipt/success` ||
      location.pathname === `/${i18n.language}/e-Receipt/error`
    ) {
      setCurrentRoute("e-Receipt");
      setUnCapitalize(true);
    } else if (location.pathname === `/${i18n.language}/unlockaccount`) {
      setCurrentRoute("Unlock Account");
    } else if (
      location.pathname === `/${i18n.language}/enrolment/success` ||
      location.pathname === `/${i18n.language}/signup`
    ) {
      setCurrentRoute("Sign Up");
    } else if (
      location.pathname === `/${i18n.language}/crmenrollment/success`
    ) {
      setCurrentRoute("Enrollment/Success for CRM");
      setUnCapitalize(true);
    } else if (location.pathname === `/${i18n.language}/resetpassword`) {
      setCurrentRoute("Reset Password");
    } else if (location.pathname === `/${i18n.language}/unsubscribe`) {
      setCurrentRoute("Unsubscribe");
    } else if (location.pathname === `/${i18n.language}/flightstatus`) {
      setCurrentRoute("Flight Status");
    } else if (
      location.pathname ===
        `/${i18n.language}/flightstatus/flightstatusdetail` ||
      location.pathname ===
        `/${i18n.language}/flightstatus/flightstatusroutedetail`
    ) {
      setCurrentRoute("Flight Status/Flight Detail");
    } else if (
      location.pathname ===
      `/${i18n.language}/flightstatus/flightstatusupcomingtripsdetail`
    ) {
      setCurrentRoute("Flight Status/Flight Detail");
    } else if (location.pathname === `/${i18n.language}/subscribe`) {
      setCurrentRoute("Subscribe");
    } else if (location.pathname === `/${i18n.language}/flightschedule`) {
      setCurrentRoute("Flight Schedule");
    } else if (
      location.pathname === `/${i18n.language}/myprofile` ||
      location.pathname === `/${i18n.language}/member/myprofile`
    ) {
      setCurrentRoute("My Profile");
      dispatch(resetAddNomineeDetails());
    } else if (
      location.pathname === `/${i18n.language}/myprofile/add-nominee`
    ) {
      setCurrentRoute("My Profile/Add Nominee");
    } else if (
      location.pathname === `/${i18n.language}/myprofile/changepassword`
    ) {
      setCurrentRoute("My Profile/Change Password");
    } else if (
      location.pathname === `/${i18n.language}/myprofile/mileage-statement`
    ) {
      setCurrentRoute("Miles Management/Mileage Statement");
    } else if (
      location.pathname === `/${i18n.language}/my-trips` ||
      location.pathname === `/${i18n.language}/my-trips/past-trips`
    ) {
      setCurrentRoute("My Trips");
    } else if (
      location.pathname === `/${i18n.language}/myprofile/memberstatuscalculator`
    ) {
      setCurrentRoute("My Profile/Member Status Calculator");
    } else if (
      location.pathname ===
      `/${i18n.language}/myprofile/memberstatuscalculator/qualifyingmilescalculator`
    ) {
      setCurrentRoute(
        "My Profile/Member Status Calculator/Qualifying Miles Calculator"
      );
    } else if (location.pathname === `/${i18n.language}/myprofile/gift-miles`) {
      setCurrentRoute("Miles Management/Gift Miles");
    } else {
      setCurrentRoute(removeLangFromRoute(location.pathname));
    }
  }, [location]);

  const routeArr = parseRouteString(currentRoute, unCapitalize);

  const formatString = (str: string) => {
    if (!str.includes("-")) {
      return str;
    }
    const parts = str.split("-");
    let formattedString = "";
    for (let i = 0; i < parts.length; i += 2) {
      if (i > 0) {
        formattedString += " // ";
      }
      formattedString += `${parts[i]}-${parts[i + 1]}`;
    }
    return formattedString;
  };

  return (
    <Breadcrumb
      className={`${styles["customBreadCrumb"]} global-content-padding`}
    >
      {destination === undefined && (
        <>
          {((!isDesktopView && routeArr.length < 2) || isDesktopView) && (
            <BreadcrumbItem
              linkAs={Link}
              linkProps={{ to: "/" }}
              active={currentRoute === "/"}
            >
              Home
            </BreadcrumbItem>
          )}
        </>
      )}
      {destination !== undefined ? (
        <>
          <BreadcrumbItem
            linkAs={Link}
            linkProps={{ to: "/" }}
            active={currentRoute === "/"}
          >
            Home
          </BreadcrumbItem>
          <TGIcon icon="chevron-right" fillColor="none" size="16" />
          <BreadcrumbItem>...</BreadcrumbItem>
          <TGIcon icon="chevron-right" fillColor="none" size="16" />
          <BreadcrumbItem
            linkAs={Link}
            linkProps={{
              to: `/${i18n.language}${routeArr[0]?.link
                .split(" ")
                .join("")}`.toLowerCase(),
            }}
            active={
              currentRoute ===
              `/${i18n.language}${routeArr[0]?.link
                .split(" ")
                .join("")}`.toLowerCase()
            }
          >
            Trip to {finalDestination.slice(0, 20)}
            {finalDestination.includes("/") && "..."}
          </BreadcrumbItem>
        </>
      ) : (
        <>
          {routeArr.map((item: any) => (
            <>
              {(!isDesktopView && routeArr.length < 2) || isDesktopView ? (
                <>
                  <TGIcon icon="chevron-right" fillColor="none" size="16" />
                  <BreadcrumbItem
                    linkAs={Link}
                    linkProps={{
                      to: `/${i18n.language}${item.link
                        .split(" ")
                        .join("")}`.toLowerCase(),
                    }}
                    active={
                      currentRoute ===
                      `/${i18n.language}${item.link
                        .split(" ")
                        .join("")}`.toLowerCase()
                    }
                  >
                    {item.text}
                  </BreadcrumbItem>
                </>
              ) : (
                <>
                  {" "}
                  <BreadcrumbItem
                    linkAs={Link}
                    linkProps={{
                      to: `/${i18n.language}${item.link
                        .split(" ")
                        .join("")}`.toLowerCase(),
                    }}
                    active={
                      currentRoute ===
                      `/${i18n.language}${item.link
                        .split(" ")
                        .join("")}`.toLowerCase()
                    }
                  >
                    {item.text}
                  </BreadcrumbItem>
                  <TGIcon icon="chevron-right" fillColor="none" size="16" />
                </>
              )}
            </>
          ))}
        </>
      )}
    </Breadcrumb>
  );
};

export default TGBreadCrumb;

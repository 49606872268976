import React from "react";
import { Button } from "react-bootstrap";
import styles from "./tg-button.module.scss";
import TGIcon from "../tg-icon";

interface Props {
  customClass?: string;
  customClassName?: string;
  label: string | undefined;
  iconName?: string;
  fillColor?: string;
  size?: string;
  align?: string;
  buttonSize?: any;
  variant: string;
  children?: any;
  onClick?: () => void;
  disabled?: boolean;
}

const TGButton = (props: Props) => {
  return (
    <Button
      className={`${styles[props.variant]} ${styles[props.buttonSize]} ${
        styles[props.customClass ? props.customClass : ""]
      } ${props.customClassName}`}
      size={props.buttonSize}
      onClick={props.onClick}
      disabled={props.disabled}
      aria-label={`button ${props.label}`}
    >
      {props.iconName && (
        <TGIcon
          icon={props.iconName}
          fillColor={props.fillColor || ""}
          size={props.size || ""}
        />
      )}
      <span>{props.label}</span>
      {props.children}
    </Button>
  );
};

export default TGButton;

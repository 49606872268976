import React, { useState } from "react";

import styles from "./tg-ad-carousel.module.scss";
import { Carousel, ProgressBar, Stack } from "react-bootstrap";

import { AdCarouselData, AdCardCarouselData } from "../../OSCI/src/models/models";
import TGIcon from "../tg-icon";
import TGButtonVariants from "../tg-button-variants";
import TGProgressBar from "../../shared/tg-progressbar";

interface Props {
  adData: Array<any>;
}

type AdData = AdCarouselData & AdCardCarouselData;

const handleNavigate = (buttonURL: string) => {
  window.open(buttonURL);
};

const TGAdCarousel: React.FC<Props> = ({ adData }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [displayIndex, setDisplayIndex] = useState(1);
  const [isHovered, setIsHovered] = useState(false);

  const handleSelect = (selectedIndex: number) => {
    setCurrentIndex(selectedIndex);

    setTimeout(() => setDisplayIndex(selectedIndex + 1), 500);
  };

  const CustomNextArrow = () => (
    <div className={styles["carousel-control-next"]}>
      <img
        src="../assets/rightArrowWhite.png"
        width={20}
        height={20}
        alt="next arrow"
      />
    </div>
  );

  const CustomPrevArrow = () => (
    <div className={styles["carousel-control-next"]}>
      <img
        src="../assets/leftArrowWhite.png"
        width={20}
        height={20}
        alt="previous arrow"
      />
    </div>
  );

  return (
    <Carousel
      className={adData.length === 1 ? styles.adCard : styles.adCarousel}
      activeIndex={currentIndex}
      onSelect={handleSelect}
      touch={true}
      nextIcon={<CustomNextArrow />}
      prevIcon={<CustomPrevArrow />}
    >
      {adData?.map((x: AdData, index: number) => {
        return (
          <Carousel.Item
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <div
              className={styles.carouselImageContainer}
              onClick={() => handleNavigate(x.buttonUrl)}
            >
              <Stack direction="horizontal" gap={0}>
                <div className={`promoCard ${styles.currentSlide}`}>
                  <img
                    src={x.image}
                    alt="CarouselImage"
                    className={`promoImage ${styles.adImage1} ${
                      adData.length !== 1 && styles.carouselBgImage
                    }`}
                  />
                  <div
                    className={`${isHovered ? styles.imageOverlay : ""} ${
                      styles.bgOverlay
                    }`}
                  ></div>
                </div>
                <div className={styles.nextSlide}>
                  {
                    /*Checking whether it has not reached last index */
                    index < adData.length - 1 && (
                      <img
                        src={adData[index + 1].image}
                        alt="CarouselImage"
                        className={`${styles.adImage2} ${
                          adData.length !== 1 && styles.carouselBgImage
                        }`}
                      />
                    )
                  }
                  {
                    /*Checking whether it has reached last index */
                    index === adData.length - 1 && (
                      <img
                        src={adData[0].image}
                        alt="CarouselImage"
                        className={`${styles.adImage2} ${
                          adData.length !== 1 && styles.carouselBgImage
                        }`}
                      />
                    )
                  }
                </div>
              </Stack>
            </div>
            {
              /*In earlier design pagination was present */
              adData.length === 1 && (
                <div className={styles.pagination}>
                  <p className={styles.paginationText}>
                    {displayIndex}/{adData.length}
                  </p>
                </div>
              )
            }
            <Carousel.Caption
              className={`${styles.adCaption} ${
                adData.length === 1
                  ? styles.cardCaption
                  : styles.carouselCaption
              }`}
            >
              <div className={styles.adCaptionSub}>
                {adData.length !== 1 && (
                  <span className={styles.locationStyle}>
                    <TGIcon
                      icon="marker-pin"
                      fillColor="none"
                      strokeColor="#FFFFFF"
                    />
                    <p className={styles.locationText}>{x.location}</p>
                  </span>
                )}
                <span className={styles.adTitle}>{x.title}</span>
                {adData.length === 1 && (
                  <p className={styles.descriptionText}>{x.description}</p>
                )}
              </div>
              <div className={!isHovered ? styles.cardLink : ""}>
                <TGButtonVariants
                  label={x.buttonText}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  borderRadius="30px"
                  boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05);"
                  bgColor="#FFFFFF"
                  padding="8px 12px 8px 16px"
                  textColor="#684B9B"
                  fontFamily="Inter"
                  fontSize="14px"
                  fontStyle="normal"
                  fontWeight="700"
                  lineHeight="160%"
                  height="42px"
                  width="120px"
                  bgColorHover="#b3b2b6"
                  onClick={() => handleNavigate(x.buttonUrl)}
                />
              </div>
            </Carousel.Caption>
            {
              /*In earlier design progress bar was present */
              adData.length === 1 && (
                <TGProgressBar
                  customProgressBarClass={styles.progressBar}
                  currentValue={(currentIndex + 1) * (100 / adData.length)}
                />
              )
            }
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
};

export default TGAdCarousel;

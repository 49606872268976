import React, { useEffect } from "react";
import TGIcon from "../tg-icon";
import styles from "./success-card.module.scss";
import useScreenSize from "../../utils/hook/useScreenSize";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { updateLoginModalState } from "../../OSCI/src/slice/signUpSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../OSCI/src/slice/RootReducer";
import Login from "../../shared/login-widget/index";
import { updateSignInState } from "../../OSCI/src/slice/ropSignUpSlice";
import isAuthenticate from "../../utils/hook/isAuthenticate";
import { resetUnSubscribeData } from "../../OSCI/src/slice/unsubscribeSlice";
import { getCrmSocialMediaValidationPending } from "../../OSCI/src/slice/crmLoginSlice";

interface SuccesCardProps {
  iconName: string;
  text: string;
  redirection: string;
  handlePanelShow: () => void;
  path?: string;
  componentName?: string;
  routeData?: any;
  type?: any;
}
export const SuccessCard: React.FC<SuccesCardProps> = ({
  iconName,
  text,
  redirection,
  handlePanelShow,
  path,
  componentName,
  routeData,
  type,
}) => {
  const loginSelector = useSelector(
    (state: RootState) => state?.osciReducer?.signUp?.loginModalVisible
  );

  const { crmSocialLoginValidationDetails } = useSelector(
    (state: RootState) => state?.osciReducer?.crmLoginData
  );
  const handlePanelCloseSuccessPage = (callback?: Function): void => {
    if (callback) {
      callback();
    }
    navigate(window.location.origin);
  };
  const isMobileView: boolean = useScreenSize().device === "mobile";
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuthenticated = isAuthenticate();
  const isCRM = sessionStorage.getItem("isROPLoggedIn");
  const isEmail = sessionStorage.getItem("email");
  const isCRMAuthenticated = isCRM === "false";
  const handleHomeRedirect = () => {
    if (path === "enrolment") {
      dispatch(updateSignInState({ updatedState: true }));
    } else if (path === "crmSocialMedia") {
      let payloadData = {
        accessToken:
          type === "google" ? routeData?.access_token : routeData?.accessToken,
        socialMediaType: type,
        socialMediaData: routeData,
      };
      dispatch(getCrmSocialMediaValidationPending(payloadData));
    } else {
      dispatch(updateSignInState({ updatedState: false }));
    }
    if (componentName === "newsletterUnsubscribe") {
      dispatch(resetUnSubscribeData());
    }
    if (path !== "crmSocialMedia") navigate(`/${i18n.language}/`);
  };

  const getUserName = () => {
    return "test";
  };

  useEffect(() => {
    if (
      Object.keys(crmSocialLoginValidationDetails).length > 0 &&
      !crmSocialLoginValidationDetails.newUser
    ) {
      navigate(`/${i18n.language}/`);
    }
  });
  return (
    <>
      {isMobileView &&
        redirection === "sidebar" &&
        componentName != "newsletterUnsubscribe" && (
          <div
            className={`${styles.mobileOuterContainer} ${
              isAuthenticated || isCRMAuthenticated ? styles.disabled : ""
            }`}
            onClick={handlePanelShow}
          >
            <div className={styles.innerContainer}>
              <TGIcon icon={iconName} fillColor="none" size="" />
              <span>{text}</span>
            </div>
            <TGIcon icon="arrow-icon" fillColor="none" size="" />
          </div>
        )}
      {isMobileView &&
        redirection === "sidebar" &&
        componentName == "newsletterUnsubscribe" && (
          <div
            className={styles.mobileOuterContainer}
            onClick={handlePanelShow}
          >
            <div className={styles.innerContainer}>
              <TGIcon icon={iconName} fillColor="none" size="" />
              <span>{text}</span>
            </div>
            <TGIcon icon="arrow-icon" fillColor="none" size="" />
          </div>
        )}
      {isMobileView && redirection === "route" && (
        <div
          className={styles.mobileOuterContainer}
          onClick={handleHomeRedirect}
        >
          <div className={styles.innerContainer}>
            <TGIcon icon={iconName} fillColor="none" size="" />
            <span>{text}</span>
          </div>
          <TGIcon icon="arrow-icon" fillColor="none" size="" />
        </div>
      )}
      {!isMobileView &&
        redirection === "sidebar" &&
        componentName != "newsletterUnsubscribe" && (
          <div
            className={`${styles.outerContainer} ${
              isAuthenticated || isCRMAuthenticated ? styles.disabled : ""
            }`}
            onClick={handlePanelShow}
          >
            <div className={styles.innerContainer}>
              <TGIcon icon={iconName} fillColor="none" size="" />
              <span>{text}</span>
            </div>
            <span>
              <TGIcon icon="arrow-icon" fillColor="none" size="" />
            </span>
          </div>
        )}
      {!isMobileView &&
        redirection === "sidebar" &&
        componentName == "newsletterUnsubscribe" && (
          <div className={styles.outerContainer} onClick={handlePanelShow}>
            <div className={styles.innerContainer}>
              <TGIcon icon={iconName} fillColor="none" size="" />
              <span>{text}</span>
            </div>
            <span>
              <TGIcon icon="arrow-icon" fillColor="none" size="" />
            </span>
          </div>
        )}
      {!isMobileView && redirection === "route" && (
        <div className={styles.outerContainer} onClick={handleHomeRedirect}>
          <div className={styles.innerContainer}>
            <TGIcon icon={iconName} fillColor="none" size="" />
            <span>{text}</span>
          </div>
          <TGIcon icon="arrow-icon" fillColor="none" size="" />
        </div>
      )}
      {loginSelector && (
        <Login
          onHidePanel={handlePanelCloseSuccessPage}
          loginName={getUserName}
          handlePanelShow={handlePanelShow}
          flow={path == "enrolment" ? "enroll" : ""}
        />
      )}
    </>
  );
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.k6GJeK25n8K49qobBrdH {
  display: flex;
  padding: 16px var(--spacing-md, 16px);
  align-items: center;
  gap: var(--spacing-sm, 12px);
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid var(--gray-stroke, #dfe0eb);
  background: #fff;
  cursor: pointer;
}

.BYQf76WA1C2VsULvegMc {
  cursor: not-allowed !important;
  background: #f1f1f1 !important;
  color: grey !important;
}

.SoQY1n_L5iW0aIWAI83R {
  display: flex;
  align-items: center;
  gap: var(--spacing-md, 16px);
  flex: 1 0 0;
}

.kRsraUZig9IfX4Iqc7sQ {
  display: flex;
  padding: 16px var(--spacing-md, 16px);
  align-items: center;
  gap: var(--spacing-sm, 12px);
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--gray-stroke, #dfe0eb);
  background: #fff;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/shared/success-card/success-card.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,qCAAA;EACA,mBAAA;EACA,4BAAA;EACA,WAAA;EACA,kBAAA;EACA,6CAAA;EACA,gBAAA;EACA,eAAA;AACF;;AAEA;EACE,8BAAA;EACA,8BAAA;EACA,sBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,4BAAA;EACA,WAAA;AACF;;AAEA;EACE,aAAA;EACA,qCAAA;EACA,mBAAA;EACA,4BAAA;EACA,mBAAA;EACA,kBAAA;EACA,6CAAA;EACA,gBAAA;EACA,eAAA;AACF","sourcesContent":[".outerContainer {\n  display: flex;\n  padding: 16px var(--spacing-md, 16px);\n  align-items: center;\n  gap: var(--spacing-sm, 12px);\n  flex: 1 0 0;\n  border-radius: 8px;\n  border: 1px solid var(--gray-stroke, #dfe0eb);\n  background: #fff;\n  cursor: pointer;\n}\n\n.disabled {\n  cursor: not-allowed !important;\n  background: #f1f1f1 !important;\n  color: grey !important;\n}\n\n.innerContainer {\n  display: flex;\n  align-items: center;\n  gap: var(--spacing-md, 16px);\n  flex: 1 0 0;\n}\n\n.mobileOuterContainer {\n  display: flex;\n  padding: 16px var(--spacing-md, 16px);\n  align-items: center;\n  gap: var(--spacing-sm, 12px);\n  align-self: stretch;\n  border-radius: 8px;\n  border: 1px solid var(--gray-stroke, #dfe0eb);\n  background: #fff;\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"outerContainer": `k6GJeK25n8K49qobBrdH`,
	"disabled": `BYQf76WA1C2VsULvegMc`,
	"innerContainer": `SoQY1n_L5iW0aIWAI83R`,
	"mobileOuterContainer": `kRsraUZig9IfX4Iqc7sQ`
};
export default ___CSS_LOADER_EXPORT___;

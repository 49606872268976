import React, { useEffect, useState, useRef } from "react";
import TGButtonVariants from "../tg-button-variants";
import TGIcon from "../tg-icon";
import styles from "./subscribe-news-letter.module.scss";
import ropImg from "../../Images/FooterPartnersRopImage.svg";
import thaiShopImg from "../../Images/ThaiShopFooterImg.svg";
import thaiMiceImg from "../../Images/ThaiMiceFooterImg.svg";
import useScreenSize from "../../utils/hook/useScreenSize";
import i18n from "i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../OSCI/src/slice/RootReducer";
import { preferenceFromDataRequest } from "../../OSCI/src/slice/newsletterPreferenceSlice";
import { useTranslation } from "react-i18next";

const SubscribeNewsLetter: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const handleUnSubcribeClick = () => {
    navigate(`/${i18n.language}/unsubscribe`);
  };
  const preferenceFromResponse = useSelector(
    (state: RootState) => state?.osciReducer?.preferenceFrom.countryList
  );
  const handleSubscribeNavigate = () => {
    navigate(`/${i18n.language}/subscribe`, {
      state: { countryList: preferenceFromResponse },
    });
  };

  useEffect(() => {
    dispatch(preferenceFromDataRequest());
  }, [dispatch]);

  let isDesktopView: boolean = useScreenSize().deviceSize.width > 767;
  const screenSize = useScreenSize().deviceSize.width;
  return (
    <div className={styles["news-letter-container"]}>
      <div className={styles["news-letter-container-wrapper"]}>
        <div className={styles["subscribe-text"]}>
          <span className={styles["header-Text"]} role="heading">
            {t("label_subscription_footer_header")}
          </span>
          <div className={styles.infoContainer}>
            <span className={styles["info-text"]}>
              {t("label_subscription_footer_content")}
            </span>
          </div>
        </div>
      </div>

      <div className={styles.buttonContainer}>
        <div className={styles.buttons}>
          <div
            className={styles.subscribeButtonContainer}
            role="button"
            tabIndex={0}
          >
            <span onClick={handleSubscribeNavigate}>
              {t("label_subscribe_button")}{" "}
              <TGIcon icon="arrow-right" size="20px" fillColor="" />
            </span>
          </div>
          <div className={styles.unsubscribeTextContainer}>
            <span
              className={styles.unsubcribeTextStyle}
              onClick={handleUnSubcribeClick}
              role="button"
              tabIndex={0}
            >
              {t("label_unsubscribe_button")}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscribeNewsLetter;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.l6FS2UZXSvk9CI9A_aWX .breadcrumb {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin: 0;
}
.l6FS2UZXSvk9CI9A_aWX .breadcrumb .breadcrumb-item {
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  line-height: 160%;
}
.l6FS2UZXSvk9CI9A_aWX .breadcrumb .breadcrumb-item:not(:active) > a {
  color: #6a6d87;
  text-decoration: none;
  font-weight: 400;
  font-family: Inter-Medium !important;
}
.l6FS2UZXSvk9CI9A_aWX .breadcrumb .breadcrumb-item:not(:active) > a:hover {
  color: #684b9b;
  font-weight: 700;
}
.l6FS2UZXSvk9CI9A_aWX .breadcrumb .breadcrumb-item.active {
  color: #684b9b;
  font-weight: 700;
}

@media (min-width: 768px) {
  .l6FS2UZXSvk9CI9A_aWX {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .l6FS2UZXSvk9CI9A_aWX {
    padding: 10px 16px;
  }
}`, "",{"version":3,"sources":["webpack://./src/shared/tg-breadcrumb/tg-breadcrumb.module.scss"],"names":[],"mappings":"AAEI;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,SAAA;EACA,SAAA;AADN;AAGM;EACE,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,iBAAA;AADR;AAIM;EACE,cAAA;EACA,qBAAA;EACA,gBAAA;EACA,oCAAA;AAFR;AAIQ;EACE,cAAA;EACA,gBAAA;AAFV;AAMM;EACE,cAAA;EACA,gBAAA;AAJR;;AAUA;EACE;IACE,iBAAA;IACA,oBAAA;EAPF;AACF;AAUA;EACE;IACE,kBAAA;EARF;AACF","sourcesContent":[".customBreadCrumb {\r\n  & :global {\r\n    .breadcrumb {\r\n      display: flex;\r\n      flex-direction: row;\r\n      align-items: center;\r\n      gap: 10px;\r\n      margin: 0;\r\n\r\n      & .breadcrumb-item {\r\n        font-family: Inter;\r\n        font-size: 14px;\r\n        font-style: normal;\r\n        line-height: 160%;\r\n      }\r\n\r\n      & .breadcrumb-item:not(:active) > a {\r\n        color: #6a6d87;\r\n        text-decoration: none;\r\n        font-weight: 400;\r\n        font-family: Inter-Medium !important;\r\n\r\n        &:hover {\r\n          color: #684b9b;\r\n          font-weight: 700;\r\n        }\r\n      }\r\n\r\n      & .breadcrumb-item.active {\r\n        color: #684b9b;\r\n        font-weight: 700;\r\n      }\r\n    }\r\n  }\r\n}\r\n\r\n@media (min-width: 768px) {\r\n  .customBreadCrumb {\r\n    padding-top: 10px;\r\n    padding-bottom: 10px;\r\n  }\r\n}\r\n\r\n@media (max-width: 767px) {\r\n  .customBreadCrumb {\r\n    padding: 10px 16px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"customBreadCrumb": `l6FS2UZXSvk9CI9A_aWX`
};
export default ___CSS_LOADER_EXPORT___;
